import AlertApi from "@/generatedapiclients/src/api/AlertApi.js";

export default class AlertService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._alertApi = new AlertApi();
  }

  async getGlobalWebAlerts() {
    const response = await this._alertApi.v1TenantTenantIdAlertGlobalWebGet(
      this._tenantId
    );

    return response.data;
  }

  async getWebAlerts(locationId) {
    const response = await this._alertApi.v1TenantTenantIdAlertLocationLocationIdWebGet(
      locationId,
      this._tenantId
    );
    return response.data;
  }
}
