<template>
  <div>
    <div
      v-for="alert in alerts"
      :key="alert.id"
      class="alert alert-info alert-dismissable rounded-0 my-0 mt-2"
      role="alert"
    >
      <div class="alert-icon">
        <i class="far fa-fw fa-bell"></i>
      </div>
      <div class="alert-message">
        <div class="float-right">
          <button
            type="button"
            class="close ml-auto text-white"
            data-dismiss="alert"
            @click="dismissAlert(alert)"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div v-html="alert.message"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertService from "@/services/AlertService.js";

export default {
  name: "LocationAlert",
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    alerts() {
      if (!this.locationAlerts) {
        return [];
      }

      if (this.spotId) {
        return this.locationAlerts.filter(alert => {
          return (
            alert.alertSpots.filter(as => as.spotId == this.spotId).length > 0
          );
        });
      }

      return this.locationAlerts;
    },
    authSource() {
      return this.$store.getters["auth/authSource"];
    }
  },
  props: {
    locationId: { type: Number },
    spotId: { type: Number }
  },
  data() {
    return {
      locationAlerts: []
    };
  },
  methods: {
    async getLocationAlerts() {
      const alertService = new AlertService(this.tenantId);
      this.locationAlerts = await alertService.getWebAlerts(this.locationId);
      if (this.authSource === "admin") {
        this.filterAlerts();
      }
    },
    dismissAlert(alert) {
      this.dismissedAlerts.push(alert.id);
      sessionStorage.setItem(
        "dismissedAlerts",
        JSON.stringify(this.dismissedAlerts)
      );
    },
    filterAlerts() {
      this.dismissedAlerts = JSON.parse(
        sessionStorage.getItem("dismissedAlerts")
      );
      if (this.dismissedAlerts != null) {
        this.locationAlerts = this.locationAlerts.filter(
          x => !this.dismissedAlerts.includes(x.id)
        );
      } else {
        this.dismissedAlerts = [];
      }
    }
  },
  watch: {
    locationId() {
      this.getLocationAlerts();
    }
  },
  created() {
    this.getLocationAlerts();
  }
};
</script>
